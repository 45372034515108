/* eslint camelcase: 0 */
import en_US from "./en_US";
import ko_KR from "./ko_KR";

const languages = {
  en_US: en_US,
  ko_KR: ko_KR,
};

export default languages;
