var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gnb_mobile_header"},[_c('img',{attrs:{"src":require("@/assets/pagenate-prev-arrow-icon-inactive_1.svg"),"alt":"","height":"40px"},on:{"click":function($event){return _vm.handleNavigation(_vm.e, false)}}}),_c('div',{staticClass:"gnb_mobile_menu_wrap"},_vm._l((_vm.menus),function(menu,index){return _c('a',{key:index,staticClass:"gnb_mobile_menu",style:([
        menu.title === 'DAO'
          ? { minWidth: '35px' }
          : menu.title === 'Tokamak Network'
          ? { minWidth: '130px' }
          : menu.title === 'Staking'
          ? { minWidth: '55px' }
          : menu.title === 'L2 Mainnet'
          ? { minWidth: '90px' }
          : menu.title === 'Bridge & Swap'
          ? { minWidth: '112px' }
          : {},
        menu.isFoucsed ? { fontWeight: 600 } : {},
        menu.isFoucsed ? { opacity: 1 } : { opacity: 0.25 },
        index === _vm.menus.length - 1 ? { marginRight: '31%' } : {},
        index === 0 ? { marginLeft: (((_vm.deviceWidth - 80 - 78) / 2) + "px") } : {} ]),attrs:{"href":menu.url},on:{"touchstart":function($event){return _vm.catchTouchStart(_vm.e)},"touchend":function($event){return _vm.handleNavigation(_vm.e)}}},[_vm._v(" "+_vm._s(menu.title)+" ")])}),0),_c('img',{attrs:{"src":require("@/assets/pagenate-prev-arrow-icon-inactive_2.svg"),"alt":"","width":"40px","height":"40px"},on:{"click":function($event){return _vm.handleNavigation(_vm.e, true)}}})])}
var staticRenderFns = []

export { render, staticRenderFns }