import home from "./home/ko_KR";
import about from "./about/ko_KR";
import solutions from "./solutions/ko_KR";

const ko_KR = {
  home: home,
  about: about,
  solutions: solutions,
};

export default ko_KR;
